<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >专栏</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/specialColumn' }">专栏设置</el-breadcrumb-item>
                <el-breadcrumb-item>新增内容</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px" size="mini" >

                <el-form-item label="名称：" label-position="left" prop="name" class="input">
                    <el-input style="width: 200px"  v-model="form.name"></el-input>
                </el-form-item>

                <el-form-item label="排序：" label-position="left" class="input">
                    <el-input style="width: 200px"  v-model="form.sort"></el-input>
                </el-form-item>

                <el-form-item label="选择书本：" label-position="left"  prop="contentId" >
                    <select-book @onSelect="onSelect"></select-book>
                </el-form-item>

                <el-form-item label="首页推荐：" label-position="left"  >
                    <el-switch v-model="form.recommend"  :active-value="1" :inactive-value="0">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{form.recommend === 1 ? '推荐' : '不推荐'}}</span>
                </el-form-item>

                <el-form-item label="状态：" label-position="left" class="input" >
                    <el-switch v-model="form.status" @change="setStatus" :active-value="1" :inactive-value="0">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{statusTxt}}</span>
                </el-form-item>

                <el-form-item>
                    <el-button size="small"  type="primary" :loading="loading" @click="onSubmit('form')">保存</el-button>
                    <router-link :to="'/specialColumn/detail/' + form.bookId + '/content'" class="router-link" ><el-button style="margin-left: 10px" size="small" >取消</el-button></router-link>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import tools from "../../../utils/tools";
    import SelectBook from "../../../components/selectBook";
    export default {
        name: "addContent",
        components:{SelectBook},
        data() {
            return {
                statusTxt:'上架',
                payStatusTxt:'关闭',
                loading:false,
                inputVisible: false,
                inputValue: '',
                uploadPath:'course',
                chapters:[],
                courseId:0,
                form: {
                    status:1,
                    type:1,
                    name:'',
                    contentId:0,
                    sort:0,
                    recommend:0,
                    specialColumnId:0
                },
                rules: {
                    name: [
                        { required: true, message: '请输入课程名称', trigger: 'blur' },
                        { min: 2, max: 24, message: '长度在 2 到 24 个字符', trigger: 'blur' }
                    ],
                    contentId: [
                        { type: 'number', required: true, message: '请选择音频', trigger: 'change' }
                    ],
                    chapterId: [
                        { type: 'number', required: true, message: '请选择所属章节', trigger: 'change' }
                    ],
                    content: [
                        { type: 'string', required: true, message: '请输入内容', trigger: 'blur' }
                    ],
                    pic: [
                        { type: 'string', required: true, message: '请输入内容', trigger: 'change' }
                    ],
                },

            }
        },
        methods: {
            ...mapActions('specialColumn',['addSpecialColumnContent']),
            s_to_hs(s){
                return tools.sToTime(s)
            },
            uploadSuccess(path){
                this.form.pic = path
            },
            setTiming(v){
                if(v === 1){
                    this.form.status = 0
                }
            },
            onSelect(book){
                this.form.contentId = book.id
                console.log(this.form)
            },

            async onSubmit(formName) {

                console.log(this.form)



                if(!this.validateForm(formName))
                {
                    this.$message.error('请填写必填选项！')
                    return false
                }

                let _this = this
                this.loading = true

                console.log(this.form)
                console.log('-----------------')


                this.addSpecialColumnContent(this.form).then(res => {
                    console.log(res)
                    if(res.ret === 0)
                    {
                        this.$message.success('新增成功！')
                        this.loading = false

                        this.$router.push('/specialColumn/detail/'+this.form.specialColumnId+'/content')
                    }
                })



            },
            validateForm(formName){
                let validate = false
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        validate = true
                        return true
                    } else {
                        validate = false
                        return false;
                    }
                });
                return validate
            },
            setStatus(status){
                if(this.form.timing === 1){
                    this.$message.info('已开启定时上架，无法设置上架')
                    this.form.status = 0
                    return
                }
                console.log(status)
                if(this.form.status){
                    this.statusTxt = '上架'
                }else{
                    this.statusTxt = '下架'
                }
                this.form.status = status
            },
            setPayStatus(){
                if(this.form.free === 1){
                    this.payStatusTxt = '开启'
                }else{
                    this.payStatusTxt = '关闭'
                    this.form.amount = 0
                    this.form.real_amount = 0
                    this.form.indate = 0
                }

            },
            handleClose(tag) {
                this.form.tags.splice(this.form.tags.indexOf(tag), 1);
            },

            showInput() {
                this.inputVisible = true;
                this.$nextTick(() => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },
            handleInputConfirm() {
                let inputValue = this.inputValue;
                if (inputValue) {
                    this.form.tags.push(inputValue);
                }
                this.inputVisible = false;
                this.inputValue = '';
            },
            onChange(content){
                console.log(content)
                this.form.content = content
            },
        },
        mounted() {
            this.form.specialColumnId = this.$route.params.id
        }
    }
</script>

<style>
    .el-tag + .el-tag {
        margin-left: 10px;
    }
    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }
    .videoImage{
        width: 200px;
        height: 120px;
    }
</style>
